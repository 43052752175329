export default [
  "FULL_TIME",
  "PART_TIME",
  "CASUAL",
  "CONTRACTED",
  "FREELANCE",
  "INTERN",
  "MOBILE",
  "RETIRED",
  "TRIAL",
  "SEASONAL",
  "DISABLED"
];
